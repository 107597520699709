// src/ui/styles/GlobalStylesComponent.tsx
'use client';
import useTheme from '@/core/hook/useTheme';
import { Global, css } from '@emotion/react';
import globalStyles from './globalStyles';

const GlobalStyles = () => {
  const { currentTheme } = useTheme();

  return (
    <Global
      styles={css`
        ${globalStyles(currentTheme)}
      `}
    />
  );
};

export default GlobalStyles;
