// src/ui/layout/footer/index.jsx
'use client';
import { css, useTheme } from '@emotion/react';
import AboutFoot from './AboutFoot';
import Foot from './Foot';
import LiContact from './LiContact';
import LiDiscover from './LiDiscover';
import LiPartners from './LiPartners';

const Footer = () => {
  const theme = useTheme();

  const footerContainerStyle = css`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    z-index: 900;
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
    @media (min-width: 1224px) {
      padding: 40px 80px;
      max-width: 60%;
      margin: auto;
      justify-content: space-between;
    }
  `;

  const footerLineStyle = css`
    height: 1px;
    margin: 10px 0;
      background-color: var(--secondaryColor);
      z-index: 1000;
    }
  `;

  return (
    <footer aria-label='Footer'>
      <div css={footerContainerStyle}>
        <AboutFoot />
        <LiDiscover />
        <LiContact />
        <LiPartners />
      </div>
      <div css={footerLineStyle}></div>
      <Foot />
    </footer>
  );
};

export default Footer;
