// src/ui/layout/ContactDropdown.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import IntLink from '@/server/link/IntLink';
import { PATH } from '@/config/path';
import { useTranslations } from 'next-intl';
import useDropdown from '@/core/hook/useDropdown';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const linkStyle = css`
  margin: 0 10px;
  position: relative;
  cursor: pointer;

  @media (min-width: 600px) {
    cursor: auto;
  }
`;

const dropdownStyle = (showDropdown) => css`
  position: absolute;
  top: 2.6rem;
  left: 0;
  background: var(--layoutBg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: ${showDropdown ? 'flex' : 'none'};
  flex-direction: column;
  animation: ${showDropdown ? fadeIn : fadeOut} 0.3s ease-in-out;
  a {
    padding: 0.5rem 1rem;
    white-space: nowrap;

    &:hover {
      background-color: var(--hoverBg);
    }
  }
  @media (max-width: 600px) {
    position: static;
    box-shadow: none;
  }
`;

const ContactDropdown = () => {
  const { showDropdown, dropdownRef, handleMouseEnter, handleMouseLeave } = useDropdown();
  const t = useTranslations('Nav');

  return (
    <nav css={linkStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={dropdownRef}>
      <IntLink href={PATH.CONTACT} passHref>
        <span aria-label={t('contact')}>{t('contact')}</span>
      </IntLink>
      <div css={dropdownStyle(showDropdown)}>
        <IntLink href={PATH.PARTNERS} passHref>
          <span aria-label={t('partners')}>{t('partners')}</span>
        </IntLink>
        <IntLink href={PATH.TEAM} passHref>
          <span aria-label={t('team')}>{t('team')}</span>
        </IntLink>
        <IntLink href={PATH.SUPPORT} passHref>
          <span aria-label={t('support')}>{t('support')}</span>
        </IntLink>
      </div>
    </nav>
  );
};

export default ContactDropdown;
