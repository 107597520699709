// src/core/libs/redux/StoreProvider.tsx
'use client';
import { ReactNode, useRef } from 'react';
import { Provider } from 'react-redux';
import { makeStore } from './store';

interface StoreProviderProps {
  children: ReactNode;
}

const StoreProvider = ({ children }: StoreProviderProps) => {
  const storeRef = useRef(makeStore());
  // Créez l'instance de store au premier rendu
  return <Provider store={storeRef.current}>{children}</Provider>;
};

export default StoreProvider;
