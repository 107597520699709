// src/ui/layout/footer/LiPartners.jsx
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import FollowLink from '@/server/link/FollowLink';

const footerSectionStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
`;

const listStyle = css`
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const listItemStyle = css`
  margin: 5px auto;
`;

const LiPartners = () => {
  const t = useTranslations('Partners');

  return (
    <section css={footerSectionStyle} aria-labelledby='footer-partners'>
      <h3 id='footer-partners'>{t('title')}</h3>
      <ul css={listStyle}>
        <li css={listItemStyle}>
          <FollowLink href='https://antonyohan.com' newTab={true} ariaLabel={t('partner1')}>
            {t('partner1')}
          </FollowLink>
        </li>
        <li css={listItemStyle}>
          <FollowLink href='https://deagle.dev' newTab={true} ariaLabel={t('partner2')}>
            {t('partner2')}
          </FollowLink>
        </li>
        <li css={listItemStyle}>
          <FollowLink href='https://starcadia.io' newTab={true} ariaLabel={t('partner3')}>
            {t('partner3')}
          </FollowLink>
        </li>
      </ul>
    </section>
  );
};

export default LiPartners;
