// src/config/path/index.ts
export const PATH = {
  HOME: '/',
  CONTACT: '/contact',
  PARTNERS: '/contact/partners',
  TEAM: '/contact/team',
  ANTON: '/contact/team/anton',
  DEAGLE: '/contact/team/deagle',
  STARCADIA: '/contact/team/starcadia',
  INDEX: '/',
  INFO: '/info',
  SERVICES: '/services',
  AUDIT: '/services/audit',
  BLOCKCHAIN: '/services/blockchain',
  WEBMASTER: '/services/dev-web',
  SUPPORT: '/support',
  TOS: '/terms-of-service',
};
