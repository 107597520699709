// src/ui/layout/contextScroll.tsx
'use client';
import { FunctionComponent, ReactNode, createContext, useState } from 'react';

type ScrollControlState = {
  controlsVisible: boolean;
  toggleVisibility: () => void;
};

const defaultState: ScrollControlState = {
  controlsVisible: true,
  toggleVisibility: () => {},
};

export const ScrollControlContext = createContext<ScrollControlState>(defaultState);

interface ProviderProps {
  children: ReactNode;
}

export const ScrollControlProvider: FunctionComponent<ProviderProps> = ({ children }) => {
  const [controlsVisible, setControlsVisible] = useState<boolean>(defaultState.controlsVisible);

  const toggleVisibility = () => {
    setControlsVisible(!controlsVisible);
  };

  return <ScrollControlContext.Provider value={{ controlsVisible, toggleVisibility }}>{children}</ScrollControlContext.Provider>;
};
