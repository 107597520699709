// src/ui/layout/navbar/index.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import DesertChrome from '@/server/title/DesertChrome';
import { grow } from '@/styles/anim/grow';
import { rotateIn } from '@/styles/anim/rotateIn';
import CrossIcon from '@/svg/CrossIcon1';
import HamburgerIcon from '@/svg/HamburgerIcon1';
import IntLink from '@/server/link/IntLink';
import { useTranslations } from 'next-intl';
import { PATH } from '@/config/path';
import AsideNavMenu from '../aside/NavMenu';
import Logo40 from '@/img/Logo40';
import ServicesDropdown from './ServicesDropdown';
import ContactDropdown from './ContactDropdown';
import LocaleSwitcherNav from '@/ui/client/btn/LocaleSwitcherNav';
import useEscape from '@/hook/key/useEscape';
import useOutside from '@/hook/click/useOutside';
import { TITLE } from '@/config/siteConfig';

const iconStyle = (show) => css`
  animation: ${show ? rotateIn : grow} 0.5s ease;
  display: flex;
  align-items: center;
`;

const navbarStyle = css`
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--layoutBg);
  z-index: 1000;
  padding: 0.3rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
`;

const navItemStyle = css`
  display: flex;
  align-items: center;
`;

const navLinksStyle = css`
  display: none;

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    margin-right: auto;
  }
`;

const rightSectionStyle = css`
  display: flex;
  align-items: center;
`;

const hamburgerStyle = css`
  display: flex;
  margin-left: 10px;
  height: 1rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
  }
`;

const titleStyle = css`
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
`;

const linkStyle = css`
  margin: 0 10px;
`;

const asideStyle = (isOpen) => css`
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: ${isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
`;

const Navbar = () => {
  const [showAside, setShowAside] = useState(false);
  const asideRef = useRef();
  const t = useTranslations('Nav');

  useEscape(() => setShowAside(false));
  useOutside(asideRef, () => setShowAside(false));

  const toggleAside = () => setShowAside((prevState) => !prevState);
  return (
    <nav css={navbarStyle} aria-label={t('navigation')}>
      <div css={navItemStyle}>
        <IntLink href={PATH.HOME} passHref>
          <span css={titleStyle}>
            <div className='logo'>
              <Logo40 alt={t('logoAlt', { title: TITLE })} />
            </div>
            <DesertChrome />
          </span>
        </IntLink>
      </div>
      <div css={navLinksStyle}>
        <IntLink href={PATH.CONTACT} passHref>
          <span css={linkStyle}>{t('contact')}</span>
        </IntLink>
        <ServicesDropdown />
        <ContactDropdown />
      </div>
      <div css={rightSectionStyle}>
        <LocaleSwitcherNav />
        <div
          css={hamburgerStyle}
          onClick={toggleAside}
          aria-label={t('menuToggle')}
          role='button'
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && toggleAside()}
        >
          <div css={iconStyle(showAside)}>{showAside ? <CrossIcon /> : <HamburgerIcon />}</div>
        </div>
      </div>
      <AsideNavMenu ref={asideRef} css={asideStyle(showAside)} isOpen={showAside} onClose={() => setShowAside(false)} />
    </nav>
  );
};

export default Navbar;
