// src/ui/layout/aside/AsideNavMenu.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { PATH } from '@/config/path';
import IntLink from '@/server/link/IntLink';
import { css } from '@emotion/react';
import { forwardRef, lazy } from 'react';
import { useTranslations } from 'next-intl';
import LazySpin from '@/util/LazySpin';
import useEscape from '@/hook/key/useEscape';

const ThemeToggleButton = lazy(() => import('@/client/btn/ThemeToggleButton'));
const LocaleSwitcher = lazy(() => import('@/client/btn/LocaleSwitcher'));

const asideStyle = css`
  position: fixed;
  right: 0;
  top: 3.55rem;
  max-width: 100%;
  max-height: 70vh;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 900;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
`;

const asideOpenStyle = css`
  transform: translateX(0);
`;

const linkContainerStyle = css`
  display: flex;
  flex-direction: column;
  margin: 0 35px 0 auto;
  padding: 20px;
  align-items: flex-end;
`;

const linkStyle = css`
  margin-bottom: 10px;
`;

const paths = [
  { path: PATH.HOME, nav: 'home' },
  { path: PATH.INFO, nav: 'info' },
  { path: PATH.SERVICES, nav: 'services' },
  { path: PATH.CONTACT, nav: 'contact' },
  { path: PATH.SUPPORT, nav: 'support' },
];

const AsideNavMenu = forwardRef(({ isOpen, onClose }, ref) => {
  const t = useTranslations('Nav');

  useEscape(onClose);

  return (
    <aside css={[asideStyle, isOpen && asideOpenStyle]} ref={ref} aria-label={t('asideNavAria')}>
      <nav css={linkContainerStyle} role='navigation'>
        {paths.map(({ path, nav }) => (
          <IntLink href={path} key={nav}>
            <span css={linkStyle}>{t(nav)}</span>
          </IntLink>
        ))}
        {isOpen && (
          <LazySpin>
            <div css={linkStyle} role='button'>
              <ThemeToggleButton />
            </div>
            <div css={linkStyle} role='button'>
              <LocaleSwitcher />
            </div>
          </LazySpin>
        )}
      </nav>
    </aside>
  );
});
AsideNavMenu.displayName = 'AsideNavMenu';
export default AsideNavMenu;
