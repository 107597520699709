// src/ui/layout/footer/Foot.jsx
import { css } from '@emotion/react';
import Link from 'next/link';
import { PATH } from '@/config/path';
import { useTranslations } from 'next-intl';
import FollowLink from '@/server/link/FollowLink';
import IntLink from '@/server/link/IntLink';
import { getCurrentYear } from '@/util/date/currentYear';

const Foot = () => {
  const currentYear = getCurrentYear();
  const t = useTranslations('Nav');

  const footStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
  `;

  const footerLegal = css`
    display: flex;
    align-items: center;
    gap: 10px;
  `;

  const linkStyle = css`
 &:hover,
  &:focus {
    text-decoration: underline;
`;

  return (
    <section css={footStyle}>
      <div>
        2019-{currentYear} |
        <FollowLink href='https://deagle.dev' newTab={true} ariaLabel={t('DeagleDevAria')} css={linkStyle}>
          Deagle.dev &reg;
        </FollowLink>
      </div>
      <ul css={footerLegal}>
        <li>
          <IntLink href={PATH.SUPPORT} passHref>
            <span aria-label={t('support')} css={linkStyle}>
              {t('support')}
            </span>
          </IntLink>
        </li>
        <li>
          <IntLink href={PATH.TOS} passHref>
            <span aria-label={t('legalNotice')} css={linkStyle}>
              {t('legalNotice')}
            </span>
          </IntLink>
        </li>
      </ul>
    </section>
  );
};

export default Foot;
