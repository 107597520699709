// src/ui/server/social/IconBar.jsx
import React from 'react';
import { css } from '@emotion/react';
import FollowLink from '@/ui/server/link/FollowLink';
import { SOCIALS } from '@/config/siteConfig';
import { useTranslations } from 'next-intl';

const barStyle = css`
  position: relative;
  width: 60vw;
  margin: 5px auto;
  display: flex;
  justify-content: space-between;
`;

const iconStyle = css`
  width: 36px;
  height: 36px;
  margin: 0 10px;
  &:hover {
    opacity: 0.8;
  }
`;

const SocialBar = ({ company }) => {
  const t = useTranslations('SocialBar');

  if (!company) {
    throw new Error(`The "company" prop is required.`);
  }

  const socialLinks = SOCIALS[company];

  if (!socialLinks) {
    throw new Error(`Unknown company: ${company}`);
  }

  return (
    <nav css={barStyle} aria-label={t('ariaLabel')}>
      {socialLinks.map(({ name, href, icon: Icon }) => (
        <FollowLink key={name} href={href} ariaLabel={t('followUs', { name })} newTab={true}>
          <Icon css={iconStyle} />
        </FollowLink>
      ))}
    </nav>
  );
};

export default SocialBar;
