// src/ui/styles/anim/rotateIn.ts
import { keyframes } from '@emotion/react';

// Définition de l'animation 'rotateIn' pour tourner un élément de 0 à 180 degrés.
export const rotateIn = keyframes`
  from {
    transform: rotate(0deg);  // Départ à 0 degré
  }
  to {
    transform: rotate(180deg);  // Fin à 180 degrés
  }
`;
