// src/components/svg/HamburgerIcon1jsx
/** @jsxImportSource @emotion/react */

const HamburgerIcon = () => {
  return (
    <div>
      <svg className='svgInfo' viewBox='0 0 64 64' width='35' height='35'>
        <line x1='16' x2='56' y1='26' y2='26' fill='none' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='2' />
        <line x1='16' x2='56' y1='36' y2='36' fill='none' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='2' />
        <line x1='16' x2='56' y1='46' y2='46' fill='none' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='2' />
      </svg>
    </div>
  );
};

export default HamburgerIcon;
