// src/ui/layout/footer/AboutFoot.jsx
import SocialBar from '@/server/contact/social/SocialBar';
import DesertChrome from '@/server/title/DesertChrome';
import { css } from '@emotion/react';
import Image from 'next/image';
import Logo60 from '@/img/Logo60';
import { useTranslations } from 'next-intl';

const styles = {
  container: css`
    padding: 20px;
    @media (min-width: 400px) {
      width: 100%;
      margin: 0 auto;
    }

    @media (min-width: 1600px) {
      width: 30%;
      margin: 0 auto;
      min-width: 220px;
    }
  `,
  logoTitleContainer: css`
    display: flex;
    align-items: center;
    gap: 20px;
  `,
  logo: css`
    margin: 0 0 15px auto;
  `,
  footerAboutTitle: css`
    margin: 0 auto 0 0;
  `,
  footerAboutText: css`
    font-size: 0.75rem;
    max-width: 420px;
    margin: 0 auto;
  `,
  socialIcons: css`
    display: flex;
    width: 100%;
    max-width: 15rem;
    margin: 25px auto;
    gap: 10px;
    justify-content: center;
  `,
};

const AboutFoot = () => {
  const t = useTranslations('Footer.about');

  return (
    <section aria-labelledby='footer-about' css={styles.container}>
      <div css={styles.logoTitleContainer}>
        <div css={styles.logo}>
          <Logo60 alt='Logo' />
        </div>
        <div id='footer-about' css={styles.footerAboutTitle}>
          <DesertChrome />
        </div>
      </div>
      <p css={styles.footerAboutText}>{t('text')}</p>
      <div css={styles.socialIcons} className='svgIcon'>
        <SocialBar company='DEAGLE' />
      </div>
    </section>
  );
};

export default AboutFoot;
