// src/ui/server/contact/Email.tsx
import { useTranslations } from 'next-intl';
import { MAIL } from '@/config/siteConfig';
import FollowLink from '@/ui/server/link/FollowLink';

interface EmailProps {
  emailKey: keyof typeof MAIL;
  className?: string;
}

const Email: React.FC<EmailProps> = ({ emailKey, className }) => {
  const t = useTranslations('Email');

  if (!(emailKey in MAIL)) {
    throw new Error(`Unknown emailKey: ${emailKey}`);
  }

  const email = MAIL[emailKey];

  return (
    <address className={className}>
      <FollowLink href={`mailto:${email}`} ariaLabel={t('sendEmailAriaLabel', { email })}>
        {email}
      </FollowLink>
    </address>
  );
};

export default Email;
