// src/ui/layout/footer/LiDiscover.jsx
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import IntLink from '@/server/link/IntLink';
import FollowLink from '@/server/link/FollowLink';
import { PATH } from '@/config/path';

const footerSectionStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
`;

const listStyle = css`
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const listItemStyle = css`
  margin: 5px auto;
`;

const LiDiscover = () => {
  const t = useTranslations('Discover');

  return (
    <section css={footerSectionStyle} aria-labelledby='footer-decouvrir'>
      <h3 id='footer-decouvrir'>{t('title')}</h3>
      <ul css={listStyle}>
        <li css={listItemStyle}>
          <IntLink href={PATH.SERVICES}>
            <span aria-label={t('services')}>{t('services')}</span>
          </IntLink>
        </li>
        <li css={listItemStyle}>
          <IntLink href={PATH.CONTACT}>
            <span aria-label={t('contact')}>{t('contact')}</span>
          </IntLink>
        </li>
        <li css={listItemStyle}>
          <FollowLink href='https://www.deagle.dev/' newTab={true} ariaLabel={t('webAgency')}>
            {t('webAgency')}
          </FollowLink>
        </li>
        <li css={listItemStyle}>
          <FollowLink
            href='https://follow.aklamio.com/ionos/fr-fr#uid=b7ecd7d2dd78444895d1a155582a4774&type=recommendation&title=IONOS&sharing_id=d0a96ef3723684c8b99b13c27a08619d&rr_uid=e75a8498250744d2c5b97f71c8e7d394&rr=fixed&recommender_uid=2c73a2e190025e3c9a2608a161f55bc0&product_id=brand&minimal=false&manual_redirect=false&locale=fr&follow=true&channel=link&route=forward'
            newTab={true}
            ariaLabel={t('ionosReferral')}
          >
            {t('ionosReferral')}
          </FollowLink>
        </li>
        <li css={listItemStyle}>
          <FollowLink href='https://partnernetwork.ionos.fr/partner/anton.yohan?origin=PartnerBadge' newTab={true} ariaLabel={t('ionosAgency')}>
            {t('ionosAgency')}
          </FollowLink>
        </li>
      </ul>
    </section>
  );
};

export default LiDiscover;
