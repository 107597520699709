// src/ui/layout/Remounted.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';

const buttonStyle = css`
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  background-color: #1e88e5;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 1000;
  font-size: 1.5rem;
  text-align: center;
  line-height: 50px;
  opacity: 0.8;
  transition:
    opacity 0.3s,
    transform 0.3s;
  will-change: transform, opacity;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    opacity: 1;
    transform: translateY(-0.15em);
  }
`;

const Remounted = () => {
  const t = useTranslations('Remounted');
  const [isVisible, setIsVisible] = useState(false);

  // Fonction optimisée pour vérifier la position de défilement
  const checkScrollBottom = (scrollPosition) => {
    const windowHeight = window.innerHeight;
    const docHeight = document.documentElement.scrollHeight;

    setIsVisible(scrollPosition > 0 && scrollPosition + windowHeight >= docHeight - 1);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    let last_known_scroll_position = 0;
    let ticking = false;
    const onScroll = () => {
      last_known_scroll_position = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          checkScrollBottom(last_known_scroll_position);
          ticking = false;
        });
        ticking = true;
      }
    };

    // Ajout de l'écouteur d'événement
    window.addEventListener('scroll', onScroll);

    // Nettoyage de l'écouteur d'événement pour éviter les fuites de mémoire
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button css={buttonStyle} onClick={scrollToTop} aria-label={t('scrollToTop')}>
          ↑
        </button>
      )}
    </>
  );
};

export default Remounted;
