// src/ui/layout/footer/LiContact.jsx
import Loc from '@/server/contact/Loc';
import Email from '@/server/contact/Email';
import Phone from '@/server/contact/Phone';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

const footerSectionStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
`;

const listStyle = css`
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const listItemStyle = css`
  margin: 5px auto;
`;

const LiContact = () => {
  const t = useTranslations('Nav');

  return (
    <section css={footerSectionStyle} aria-labelledby='footer-contact'>
      <h3 id='footer-contact'>{t('contact')}</h3>
      <ul css={listStyle}>
        <li css={listItemStyle}>
          <Loc locationKey='DEAGLE' />
        </li>
        <li css={listItemStyle}>
          <Phone phoneKey='ANTON' />
        </li>
        <li css={listItemStyle}>
          <Phone phoneKey='DEAGLE' />
        </li>
        <li css={listItemStyle}>
          <Email emailKey='ANTON' />
        </li>
      </ul>
    </section>
  );
};

export default LiContact;
