// src/ui/styles/globalStyles.js
'use client';
import { css } from '@emotion/react';
import { antonFontFace, averageSansFontFace, judsonFontFace } from './fontFaces';

const sizes = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
  xxl: '1920px',
  xxxl: '2560px',
};

const globalStyles = (theme) => css`
  :root {
    --mainBg: ${theme.mainBg};
    --layoutBg: ${theme.layoutBg};
    --cardBg: ${theme.cardBg};
    --cardTxt: ${theme.cardTxt};
    --text: ${theme.text};
    --highlight: ${theme.highlight};
    --secondaryColor: ${theme.secondaryColor};
    --hoverBg: ${theme.secondaryColor};
  }

  ${antonFontFace}
  ${judsonFontFace}
  ${averageSansFontFace}

  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  html,
  body {
    font-family:
      'Average Sans',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    max-width: 100vw;
    margin: 0;
    line-height: 1.6;
  }

  html,
  select,
  option {
    color: var(--text);
  }

  body {
    background: var(--mainBg);
    color: var(--text);
  }

  main {
    min-height: 100vh;
    margin: 0 auto;
  }

  footer,
  aside {
    background: var(--layoutBg);
  }

  footer {
    padding: 20px;
    text-align: center;
  }

  h1 {
    font-family: 'Judson', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
    margin: 5px auto;
  }

  button {
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    font: inherit;
    outline: inherit;
  }

  img {
    margin: auto;
  }

  .svgInfo {
    fill: var(--text);
    stroke: var(--text);
  }

  .svgIcon {
    width: 24px;
    height: 24px;
    fill: var(--text);
  }

  button,
  svg,
  a {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
  }

  a:hover {
    color: var(--highlight);
  }

  p {
    font-family: 'Judson', sans-serif;
    font-size: 0.755rem;
  }

  p.center {
    text-align: center;
  }

  p.left {
    text-align: left;
  }

  p.right {
    text-align: right;
  }

  ul {
    list-style: none;
  }

  li {
    margin-top: 0.7rem;
  }

  @media (min-width: ${sizes.xs}) {
  }
  @media (min-width: ${sizes.sm}) {
  }
  @media (min-width: ${sizes.md}) {
  }
  @media (min-width: ${sizes.lg}) {
  }
  @media (min-width: ${sizes.xl}) {
  }
  @media (min-width: ${sizes.xxl}) {
  }
`;

export default globalStyles;
