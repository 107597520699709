// src/assets/img/Logo.jsx
/** @jsxImportSource @emotion/react */
import Image from 'next/image';
import useSelectImg from '@/hook/useSelectImg';

const Logo40 = ({ alt }) => {
  const logoPng = '/logo.png';
  const logoWebp = '/logo.webp';
  const altText = alt || "Logo de l'entreprise";

  const selectedLogo = useSelectImg(logoPng, logoWebp, undefined, logoPng);

  return <Image src={selectedLogo} alt={altText} width={45} height={45} priority />;
};

export default Logo40;
