// src/ui/styles/fontFaces.ts
import { css } from '@emotion/react';

export const antonFontFace = css`
  @font-face {
    font-family: 'Anton';
    src: url('/fonts/anton/Anton-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

export const judsonFontFace = css`
  @font-face {
    font-family: 'Judson';
    src: url('/fonts/judson/Judson-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

export const averageSansFontFace = css`
  @font-face {
    font-family: 'Average Sans';
    src: url('/fonts/average_sans/AverageSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;
