// src/ui/server/contact/Phone.ts
import React from 'react';
import { useTranslations } from 'next-intl';
import { PHONE } from '@/config/siteConfig';
import FollowLink from '@/ui/server/link/FollowLink';

interface PhoneProps {
  phoneKey: keyof typeof PHONE;
  className?: string;
}

const Phone: React.FC<PhoneProps> = ({ phoneKey, className }) => {
  const t = useTranslations('Phone');
  if (!(phoneKey in PHONE)) {
    throw new Error(`Unknown phoneKey: ${phoneKey}`);
  }

  const { name, number, formatted } = PHONE[phoneKey];

  return (
    <address className={className} aria-label={t('addressAriaLabel', { name, formatted })}>
      <FollowLink href={`tel:${number}`} ariaLabel={t('callAriaLabel', { name, formatted })}>
        <span role='img' aria-hidden='true' aria-label={t('iconAriaLabel', { name })}>
          📞
        </span>
        {formatted}
      </FollowLink>
    </address>
  );
};

export default Phone;
