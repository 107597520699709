// src/ui/layout/Downed.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

// Style CSS-in-JS pour le bouton avec @emotion/react
const buttonStyle = css`
  position: fixed;
  top: 5rem;
  right: 1rem;
  background-color: #1e88e5;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 100;
  font-size: 1.5rem;
  text-align: center;
  line-height: 50px;
  opacity: 0.8;
  transition:
    opacity 0.3s,
    transform 0.3s;
  will-change: transform, opacity;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    opacity: 1;
    transform: translateY(0.15em);
  }
`;

const Downed = () => {
  const [isVisible, setIsVisible] = useState(true);

  // Fonction pour descendre progressivement par incrément de 50vh
  const scrollToNextSection = () => {
    window.scrollBy({
      top: window.innerHeight / 2, // Descend de 50vh
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const checkScrollTop = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      setIsVisible(scrollPosition + windowHeight < docHeight);
    };

    window.addEventListener('scroll', checkScrollTop);

    // Initial check to determine if button should be visible
    checkScrollTop();

    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, []);

  return isVisible ? (
    <button css={buttonStyle} onClick={scrollToNextSection}>
      ↓
    </button>
  ) : null;
};

export default Downed;
