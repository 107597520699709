import(/* webpackMode: "eager" */ "C:\\prod\\anton\\06-29-anton-prod-ok\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "C:\\prod\\anton\\06-29-anton-prod-ok\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\preload-css.js");
;
import(/* webpackMode: "eager" */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\core\\lib\\redux\\StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\core\\lib\\toast\\ToastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\ui\\layout\\Downed.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\ui\\layout\\Footer\\index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\ui\\layout\\navbar\\index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\ui\\layout\\Remounted.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\ui\\layout\\Scroll.jsx");
;
import(/* webpackMode: "eager" */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\ui\\styles\\GlobalStylesComponent.tsx");
