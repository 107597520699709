// src/config/AppConfig.ts
// Si next-intl n'exporte pas LocalePrefix, définissez votre propre type
type LocalePrefix = 'always' | 'as-needed' | 'never';
// Définir le préfixe de locale, par exemple : 'as-needed'
const localePrefix: LocalePrefix = 'as-needed';
// FIXME: Mettez à jour ce fichier de configuration en fonction des informations de votre projet
export const AppConfig = {
  name: 'antonyohan.com',
  locales: ['en-US', 'fr-FR', 'es-ES'],
  defaultLocale: 'en-US',
  localeDetection: true,
  localePrefix,
};
