// src/core/hook/useTheme.ts
import { AppDispatch, RootState } from '@/redux/store';
import { setTheme } from '@/redux/themeSlice';
import { ThemeType, darkTheme, lightTheme } from '@/styles/theme';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useTheme = () => {
  const dispatch = useDispatch<AppDispatch>(); // Utilisez AppDispatch pour le typage de useDispatch
  const currentThemeValue = useSelector((state: RootState) => state.theme.value); // Utilisez RootState pour le typage de useSelector

  const currentTheme = useMemo<ThemeType>(() => {
    return currentThemeValue === 'dark' ? darkTheme : lightTheme;
  }, [currentThemeValue]);

  const toggleTheme = () => {
    const newThemeValue = currentThemeValue === 'dark' ? 'light' : 'dark';
    dispatch(setTheme(newThemeValue));
  };

  return { currentTheme, toggleTheme };
};

export default useTheme;
