// src/ui/layout/Scroll.jsx
'use client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ScrollControlContext } from './contextScroll';
import styles from './scroll.module.css';

const ScrollComponent = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isFirstRender, setFirstRender] = useState(true);
  const { controlsVisible } = useContext(ScrollControlContext);

  const handleScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    if (isFirstRender) {
      setFirstRender(false);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isFirstRender]);

  const scrollTo = (destination) => {
    window.scrollTo({
      top: destination,
      behavior: 'smooth',
    });
  };

  const scrollBy70vh = (direction = 1) => {
    window.scrollBy(0, window.innerHeight * 0.7 * direction);
  };

  const BUTTONS = [
    {
      isVisible: () => scrollPosition > window.innerHeight * 0.1,
      onClick: () => scrollTo(0),
      label: '⇧',
      ariaLabel: 'Button to scroll to top of the page',
    },
    {
      isVisible: () => scrollPosition > window.innerHeight * 0.1,
      onClick: () => scrollBy70vh(-1),
      label: '↑',
      ariaLabel: 'Button to scroll up by 70 view height',
    },
    {
      isVisible: () => scrollPosition < document.body.scrollHeight - window.innerHeight - window.innerHeight * 0.1,
      onClick: () => scrollBy70vh(1),
      label: '↓',
      ariaLabel: 'Button to scroll down by 70 view height',
    },
    {
      isVisible: () => scrollPosition < document.body.scrollHeight - window.innerHeight - window.innerHeight * 0.1,
      onClick: () => scrollTo(document.body.scrollHeight),
      label: '⇩',
      ariaLabel: 'Button to scroll to bottom of the page',
    },
  ];

  return (
    <div className={styles.container} aria-label='Scroll controls'>
      {!isFirstRender &&
        BUTTONS.map((button, index) => (
          <button
            key={button.label}
            onClick={button.onClick}
            className={styles.button}
            aria-label={button.ariaLabel}
            style={{
              visibility: controlsVisible && button.isVisible() ? 'visible' : 'hidden',
            }}
          >
            {button.label}
          </button>
        ))}
    </div>
  );
};

export default ScrollComponent;
