// src/core/libs/redux/themeSlice.ts
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

type Theme = 'light' | 'dark';

// Détection de l'environnement d'exécution
const isClient: boolean = typeof window === 'object';

// Fonction pour charger le thème depuis le stockage local
const loadThemeFromLocalStorage = (): Theme => {
  if (!isClient) {
    return 'dark'; // Valeur par défaut pour le rendu côté serveur
  }
  const savedTheme = localStorage.getItem('theme') as Theme | null;
  return savedTheme || 'dark'; // 'dark' est le thème par défaut
};

// État initial du thème
interface ThemeState {
  value: Theme;
}

const initialState: ThemeState = {
  value: loadThemeFromLocalStorage(),
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    // Action pour basculer entre les thèmes clair et sombre
    toggleTheme: (state) => {
      const newTheme: Theme = state.value === 'dark' ? 'light' : 'dark';
      state.value = newTheme;
      if (isClient) {
        localStorage.setItem('theme', newTheme); // Mise à jour du stockage local
      }
    },
    // Action pour définir le thème
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.value = action.payload;
      if (isClient) {
        localStorage.setItem('theme', action.payload); // Mise à jour du stockage local
      }
    },
  },
});

// Actions exportées pour une utilisation dans les composants
export const { toggleTheme, setTheme } = themeSlice.actions;

// Action pour initialiser le thème
export const initializeTheme = createAction('theme/initialize', () => {
  return {
    payload: loadThemeFromLocalStorage(),
  };
});

export default themeSlice.reducer;
