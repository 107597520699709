// src/core/util/LazySpin.jsx
import React, { FC, ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Spinner from '@/server/Spinner';

interface LazySpinProps {
  children: ReactNode;
  fallback?: ReactElement;
}

/**
 * LazySpin is a wrapper around React.Suspense with a default fallback UI.
 * It shows a spinner while the children components are being lazy-loaded.
 *
 * @param children The content to render once it has loaded.
 * @param fallback The content to render while loading.
 */
const LazySpin: FC<LazySpinProps> = ({ children, fallback = <Spinner aria-label='Loading ...' /> }) => {
  return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
};

LazySpin.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.element,
};

export default LazySpin;
