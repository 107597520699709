// src/compopnents/svg/CrossIcon.jsx
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const crossStyle = css`
  // Autres styles si nécessaire
`;

const CrossIcon = ({ onClick }) => {
  return (
    <div className='svgInfo' css={crossStyle} onClick={onClick}>
      <svg viewBox='0 0 24 24' width='35' height='35'>
        <path d='M16 8L8 16M8.00001 8L16 16' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </div>
  );
};

export default CrossIcon;
