// src/ui/client/btn/LocaleSwitcherNav.tsx
'use client';
/** @jsxImportSource @emotion/react */
import { useLocale } from 'next-intl';
import type { ChangeEventHandler } from 'react';
import { css, ClassNames } from '@emotion/react';
import { usePathname, useRouter } from '@/navigation';
import { AppConfig } from '@/config/AppConfig';

const localeFlags: Record<string, string> = {
  'en-US': '🇺🇸',
  'fr-FR': '🇫🇷',
  'es-ES': '🇪🇸',
};

const localeSwitcherStyles = css`
  display: flex;
  align-items: center;
  border: solid 1px #070009;
  border-radius: 5px;

  label {
    font-size: 1.5rem;
  }

  select {
    background-color: var(--layoutBg);
    border: none;
    appearance: none;
    padding: 0.3rem;
    font-size: 1rem;
    cursor: pointer;
    transition:
      border-color 0.2s,
      color 0.2s;

    &:hover {
      border-color: #888;
    }

    &:focus {
      outline: none;
      border-color: #005fcc;
    }
    &:focus + .arrow {
      transform: rotate(180deg); /* Faire pivoter la flèche lorsqu'en focus */
    }
  }

  .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    margin: 5px;
    transition: transform 0.2s; /* Transition de l'animation */
  }
`;

const flagStyles = css`
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;

  img {
    margin-right: 0.5rem;
  }
`;

export default function LocaleSwitcherNav() {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    router.push(pathname, { locale: event.target.value });
    router.refresh();
  };

  return (
    <ClassNames>
      {({ css }) => (
        <div className={css(localeSwitcherStyles)}>
          <select id='locale-switcher' aria-label='Switch Language' defaultValue={locale} onChange={handleChange}>
            {AppConfig.locales.map((elt) => (
              <option className={css(flagStyles)} key={elt} value={elt}>
                {localeFlags[elt]}
              </option>
            ))}
          </select>
          <span className='arrow'></span>
        </div>
      )}
    </ClassNames>
  );
}
