// src/ui/server/Spinner.jsx
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, keyframes } from '@emotion/react';

// Définir les keyframes pour l'animation du spinner
const orbit = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styles pour le container du spinner avec css emotion
const loaderStyle = css`
  --size-loader: 50px;
  --size-orbe: 10px;
  width: var(--size-loader);
  height: var(--size-loader);
  position: relative;
  transform: rotate(45deg);
  margin: 20px auto;
`;

// Styles pour chaque orbe avec css emotion
const orbeStyle = (index) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  --delay: calc(${index} * 0.1s);
  animation: ${orbit} 1.5s ease-in-out var(--delay) infinite;
  opacity: calc(1 - calc(0.2 * ${index}));

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: var(--size-orbe);
    height: var(--size-orbe);
    background-color: #3ae374;
    border-radius: 50%;
    box-shadow: 0 0 20px 2px #3ae374;
  }
`;

// Composant Spinner
const Spinner = () => (
  <div css={loaderStyle} aria-label='Loading ... ' role='status'>
    {[...Array(5)].map((_, index) => (
      <div css={orbeStyle(index)} key={index} />
    ))}
  </div>
);

export default React.memo(Spinner);
