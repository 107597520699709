// src/core/libs/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './themeSlice';

export const makeStore = () => {
  return configureStore({
    reducer: {
      theme: themeReducer, // add the theme slice to the store
    },
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
