// src/ui/server/contact/Loc.tsx
import React from 'react';
import { useTranslations } from 'next-intl';
import { LOCATIONS } from '@/config/siteConfig';
import FollowLink from '@/ui/server/link/FollowLink';

interface LocProps {
  locationKey: keyof typeof LOCATIONS;
  className?: string;
}

const Loc: React.FC<LocProps> = ({ locationKey, className }) => {
  const t = useTranslations('Loc');
  const location = LOCATIONS[locationKey];

  if (!location) {
    throw new Error(`Unknown locationKey: ${locationKey}`);
  }
  return (
    <FollowLink href={location.mapLink} newTab={true} ariaLabel={t('viewMapAriaLabel', { name: location.name })} className={className}>
      <address>
        {location.address.split(',').map((line, index) => (
          <React.Fragment key={index}>
            {line.trim()}
            {index < location.address.split(',').length - 1 && <br />}
          </React.Fragment>
        ))}
      </address>
      {t('viewMapAriaLabel', { name: location.name })}
    </FollowLink>
  );
};

export default Loc;
