// src/ui/styles/anim/grow.ts
import { keyframes } from '@emotion/react';

// Définition de l'animation 'grow' pour agrandir un élément de zéro à sa taille normale.
export const grow = keyframes`
  from {
    transform: scale(0);  // Départ réduit à zéro
    opacity: 0;           // Complètement transparent au début
  }
  to {
    transform: scale(1);  // Fin à taille normale
    opacity: 1;           // Complètement visible à la fin
  }
`;
