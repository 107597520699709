// src/ui/styles/theme.ts
'use client';
export interface ThemeType {
  title: string;
  text: string;
  divBg: string;
  cardBg: string;
  cardTxt: string;
  mainBg: string;
  layoutBg: string;
  secondaryColor: string;
  highlight: string;
  svgColor: string;
}
export const sharedColors = {};
export const lightTheme: ThemeType = {
  title: '#9E6C00',
  text: '#333',
  divBg: '#0090FF',
  cardBg: '#7cc2de',
  cardTxt: '#242a58;',
  mainBg:
    'repeating-linear-gradient(90deg, rgba(201, 201, 201, 0.06) 0px, rgba(201, 201, 0.06) 1px, transparent 1px, transparent 96px), repeating-linear-gradient(0deg, rgba(201, 201, 201, 0.06) 0px, rgba(201, 201, 201, 0.06) 1px, transparent 1px, transparent 96px), repeating-linear-gradient(0deg, rgba(201, 201, 201, 0.09) 0px, rgba(201, 201, 201, 0.09) 1px, transparent 1px, transparent 12px), repeating-linear-gradient(90deg, rgba(201, 201, 201, 0.09) 0px, rgba(201, 201, 201, 0.09) 1px, transparent 1px, transparent 12px), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));',
  layoutBg: 'linear-gradient(135deg, #497ddb  0%, #528fd3 50%, #4e86ea 100%)',
  secondaryColor: '#f3923d',
  highlight: '#79ffff;',
  svgColor: '#000',
};
export const darkTheme: ThemeType = {
  title: '#FFCA16',
  text: '#f4f4f4',
  divBg: '#23AFD0',
  cardBg: '#36adb3',
  cardTxt: '#1c2c34',
  mainBg:
    'repeating-linear-gradient(0deg, rgb(6, 6, 6) 0px, rgb(6, 6, 6) 1px, transparent 1px, transparent 21px), repeating-linear-gradient(90deg, rgb(6, 6, 6) 0px, rgb(6, 6, 6) 1px, transparent 1px, transparent 21px), linear-gradient(90deg, rgb(23, 23, 23), rgb(23, 23, 23));',
  layoutBg: 'linear-gradient(135deg, #152229 0%, #1e2f38 25%, #152229 50%, #1e2f38 75%, #152229 100%)',
  secondaryColor: '#fd7f15',
  highlight: '#00ff2d',
  svgColor: '#fff',
};
