// src/ui/layout/ServicesDropdown.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { useState, useRef } from 'react';
import { css, keyframes } from '@emotion/react';
import IntLink from '@/server/link/IntLink';
import { PATH } from '@/config/path';
import { useTranslations } from 'next-intl';
import useDropdown from '@/core/hook/useDropdown';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const linkStyle = css`
  margin: 0 10px;
  position: relative;
  cursor: pointer;

  @media (min-width: 600px) {
    cursor: auto;
  }
`;

const dropdownStyle = (showDropdown) => css`
  position: absolute;
  top: 3.6rem;
  left: 50%;
  transform: translate(-10%, -50%);
  width: 80%;
  max-width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: ${showDropdown ? 'block' : 'none'};
  animation: ${showDropdown ? fadeIn : fadeOut} 0.3s ease-in-out;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  li {
    display: inline;
    a {
      padding: 0.1rem 1rem;
      white-space: nowrap;
      display: block;
      background: var(--layoutBg);
      border-radius: 5px;

      &:hover {
        background-color: var(--hoverBg);
      }
    }
  }
  @media (max-width: 600px) {
    top: auto;
    left: auto;
    transform: none;
    position: static;
    box-shadow: none;
  }
`;

const ServicesDropdown = () => {
  const { showDropdown, dropdownRef, handleMouseEnter, handleMouseLeave } = useDropdown();
  const t = useTranslations('Nav');

  return (
    <div css={linkStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={dropdownRef}>
      <IntLink href={PATH.SERVICES} passHref>
        <span aria-label={t('services')}>{t('services')}</span>
      </IntLink>
      <div css={dropdownStyle(showDropdown)} role='menu'>
        <ul role='menu'>
          <li>
            <IntLink href={PATH.AUDIT} passHref>
              <span aria-label={t('audit')}>{t('audit')}</span>
            </IntLink>
          </li>
          <li>
            <IntLink href={PATH.BLOCKCHAIN} passHref>
              <span aria-label={t('blockchain')}>{t('blockchain')}</span>
            </IntLink>
          </li>
          <li>
            <IntLink href={PATH.WEBMASTER} passHref>
              <span aria-label={t('webmaster')}>{t('webmaster')}</span>
            </IntLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ServicesDropdown;
