// src/ui/server/title/DesertChrome.jsx
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';

const chromeEffect = keyframes`
  0% { background-position: -100px 0; }
  100% { background-position: 500px 0; }
`;

// Style pour le titre
const titleStyle = (isChrome) => css`
  padding: 1px 20px;
  background: #000;
  color: #fff;
  position: relative;
  font-size: 0.7rem;
  z-index: 10;
  overflow: hidden;

  @media (min-width: 360px) {
    font-size: 1rem;
  }

  @media (min-width: 1000px) {
    font-size: 1.5rem;
  }

  @media (min-width: 2000px) {
    font-size: 2rem;
  }

  ${isChrome &&
  css`
    font-family: 'Titillium Web', sans-serif;
    font-style: italic;
    margin: 0;
    line-height: 1;
    background-image: linear-gradient(#378dbc 0%, #b6e8f1 46%, #ffffff 50%, #32120e 54%, #ffc488 58%, #582c11 90%, #ec9b4e 100%);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:before {
      content: 'Anton Yohan';
      position: absolute;
      left: 50%; // Centrage horizontal
      top: 0;
      transform: translateX(-50%); // Décalage pour un centrage précis
      z-index: 0;
      background-image: linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%);
      background-position: -1000px 0;
      background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${chromeEffect} 13s linear infinite;
      white-space: nowrap;
      font-size: inherit;
      line-height: 1;
    }
  `}
`;

const DesertChrome = () => {
  return (
    <h2 css={titleStyle(true)} className='chrome'>
      Anton Yohan
    </h2>
  );
};

export default DesertChrome;
